.izoh {
    background-color: #f7f7f7;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
  }
  .izoh i {
    margin-right: 7px;
  }
  .pages {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .container .row .mycollg7{
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.521);
  }
  #tables a{
    color: black;
  }
  #tables a:hover{
    color: blue;
    text-decoration: underline !important;
  }
  .links:hover{
    color: blue;
  }
  #mydownload{
    background-color: blue !important;
    color: white !important;
    padding: 5px 17px;
    border-radius: 20px;
    margin-left: 7px;
  }
  @media only screen and (max-width: 600px) {
    #tables {
      margin-top: 30px;
    }
  }
  .paginates{
    overflow: hidden;
  }
  
  
  .pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
  }
  .pagination > .active > a {
    background-color: #47ccde;
    border-color: #47ccde;
    color: #fff;
  }
  .pagination > li > a {
    border: 1px solid #47ccde;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus {
    background-color: #47ccde;
    border-color: #47ccde;
    outline: none;
  }
  .pagination > li > a,
  .pagination > li > span {
    color: #47ccde;
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span,
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-radius: unset;
  }
  
  
  
  
  
  