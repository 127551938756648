@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,500;1,500&display=swap");

body {
  position: relative;
  background-image: url(./componenet/img/YsWRDA.gif);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  background-attachment: fixed;
  opacity: 1;
  font-family: "DM Sans", sans-serif !important;
}

.pdf-container {
  width: 100%;
  height: 800px;
  background-color: #e4e4e4;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-msg {
  width: 100%;
  color: red;
  font-size: 14px;
  font-weight: 600;
}
body::-webkit-scrollbar {
  width: 10px;
}

/* Track */
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: rgb(11, 156, 103);
  border-radius: 10px;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: rgb(11, 156, 103);
}

html {
  scroll-behavior: smooth;
}
.arrowTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
}
.ReactLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: transparent;
}

.animators {
  width: 100%;
  height: 80vh;
  align-items: center;
}
.tops {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 15%);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: linear 0.4s;
}
.tops i {
  color: #198754;
}
.tops:hover {
  background-color: #198754;
  color: white;
}
.tops:hover i {
  color: white;
}

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid rgb(34, 207, 92);
  border-color: rgb(34, 207, 92) transparent rgb(34, 207, 92) transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
