#malumot {
  border-radius: 0 !important;
}

#malumot h3 {
  font-weight: 300;
  color: #707070;
  letter-spacing: 0.7px;
}

.myprofilImg {
  border-radius: 12px;
}
.myprofilImg:hover {
  transform: scale(1.1);
}
#col8 {
  display: flex;
  align-items: center;
}
#malumot p {
  font-size: 18px !important;
  line-height: 2;
}
#profil {
  /* padding: 18px; */
  border-radius: 12px;
  background-color: transparent;
  border: none;
}
#col8fi {
  background-color: #01633a;
  border-radius: 12px;
  /* height: 300px; */
}
#textlar {
  color: white;
}
#textlar h1 {
  letter-spacing: 5px;
  font-size: 50px;
  color: white;
  margin-bottom: 0px;
  margin-top: 18px;
}
#textlar p {
  color: white;
}
#col8fi img {
  transition: 0.4s linear;
}
/* #col8fi img:hover {
  transform: scale(1.1);
} */
#myrowbg .card {
  background-color: transparent;
}
#myrowbg .card a,
p {
  font-size: 17px;
}

#cccc {
  background-color: rgba(255, 255, 255, 0.418);
  border-radius: 20px;
  padding: 40px 20px 60px 20px;
}
#messegss {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px;
  border-radius: 20px;
}

.imgGifSec,
.card {
  background-color: transparent;
}
.index {
  color: black;
}
.index:hover {
  color: blue;
}
#fauser {
  border-radius: 50%;
  border: 1px solid black;
  padding: 3px;
  box-sizing: border-box;
  margin: 0 5px;
}
#fasignal {
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  padding: 2px;
  box-sizing: border-box;
  margin: 0 5px;
}
.row2 .card {
  background-color: #f7f7f7;
  border: none;
  border-radius: 0;
  padding: 10px 0 0 10px;
  box-sizing: border-box;
}
.row2 .div {
  width: 40px;
  height: 40px;
  background-color: orangered;
  border-radius: 50%;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.row2 #xabarlar {
  display: flex;
  align-items: center;
  border-bottom: 1px solid gray;
  padding: 10px 0;
  margin-top: 10px;
}
.row2 #xabarlar .gmail {
  color: orangered;
  font-size: 16px;
}
.row2 #xabarlar .gmail:hover {
  color: red;
  border-bottom: 1px solid orangered;
}
@media only screen and (max-width: 600px) {
  body {
    background-color: lightblue;
  }
  .row2 .card {
    margin-bottom: 20px;
  }
  #malumot {
    margin-top: 30px;
  }

  /* #imgCenter {
    display: flex;
    justify-content: center;
  } */
  #textlar h1 {
    letter-spacing: 5px;
    font-size: 29px;
    color: white;
    margin-bottom: 0px;
    margin-top: 18px;
  }
  #textlar p {
    color: white;
  }
}

.carusel7 {
  overflow: hidden;
}
#mycarusel img {
  width: 100%;
  height: 500px !important;
}
.images77 {
  background: linear-gradient(#01633a, #318c22);
  /* background: url(https://wallpapercave.com/wp/wp2757874.gif); */
  /* background: linear-gradient(135deg, green 60%, cyan); */
  /* clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%); */
  padding-bottom: 50px;
  clip-path: polygon(
    20% 0%,
    80% 0%,
    100% 20%,
    100% 80%,
    80% 100%,
    20% 100%,
    0% 80%,
    0% 20%
  );

  z-index: -1;
}

.images77 h2 {
  color: white !important;
}
