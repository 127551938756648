.text {
  text-align: center;
}
header {
  font-family: "Roboto Condensed", sans-serif;
  background-image: url(../img/subject-imgs.jpg);
  /* background-image: url(../img/photo_2022-04-12_21-33-38.jpg); */
  /* background: linear-gradient(green 75%, rgb(133, 133, 226) 100%); */
  /* background: linear-gradient(0.25turn, #319b3a, #426329, #56bebe); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
}
.kirish:hover {
  color: yellow;
}
.mystiky {
  position: sticky;
  top: 0;
}
.chiziqYellow {
  width: 100%;
  height: 3px;
  background-color: rgb(255, 255, 0);
}
header .logoh6 h6 {
  line-height: 1.3;
  margin-top: 20px;

  color: white;
  cursor: pointer;
}
header a {
  text-decoration: none;
  color: white;
}
header i {
  border: 1px solid white;
  border-radius: 50%;
  padding: 3px;
  box-sizing: border-box;
  margin-right: 5px;
}

header .linkLogo {
  text-decoration: none;
  color: black;
}

nav ul #navitem #navlink {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 17px;
  color: white !important;
  letter-spacing: 0.6px;
  border-top: 2px solid transparent;
  transition: 0.4s linear;
}
nav ul #navitem #navlink:hover {
  color: black !important;
}
nav ul #navitem {
  display: flex;
  align-items: center;
  font-size: 16px;
}
#nav-link {
  display: inline-block;
  position: relative;
  padding-bottom: 3px;
}
#nav-link::after {
  content: "";
  display: block;

  margin: 0;
  height: 3px;
  width: 0;
  background-color: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
}
#nav-link:hover::after {
  width: 100%;
  background-color: black;
}
.mySelect select {
  border-radius: 20px !important;
}
nav{
  background-color: #01633A !important;
}
.mySelect select option {
  border-radius: 20px !important;
}
.mySelect select option:hover {
  background-color: cyan;
}
nav ul li a.active,
nav ul #navitem #navlink:focus {
  border-top: 2px solid black;
}
nav #navlink {
  color: black;
}
.logo img {
  border-radius: 50% !important;
}
.responsives {
  display: block;
}
.sahifa {
  padding-right: 0 !important;
  padding-left: 5px !important;
}

.dropbtn {
  background-color: transparent;
  color: white;
  border: none;
  margin-top: 2.3px;
  margin-left: 3px;
  font-size: 16px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
#dropdowncontent {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  border-radius: 10px;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */

#dropdowncontent .links {
  color: black;
  padding: 10px 10px;
  margin: 0;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
#dropdowncontent .links:hover {
  background-color: #ddd;
  border-radius: 10px;
}

/* Show the dropdown menu on hover */
.dropdown:hover #dropdowncontent {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  color: black;
}

@media screen and (max-width: 480px) {
  nav ul #navitem #navlink {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 5px;
  }
}
@media screen and (max-width: 523px) {
  #dropdowncontent {
    position: relative;
  }
  #dropdowncontent .dropbtn {
  }
  #dropdowncontent .dropbtn .responsives {
    display: block !important;
  }
}
@media screen and (max-width: 991px) {
  .mySelect {
    display: none;
  }
  .dropbtn {
    margin-left: 5px !important;
    display: block;
  }
  .mySelect2 select {
    border-radius: 20px !important;
  }
}
.card11 {
  /* background-color: rgba(255,255,255,0.1);
  border-radius: 20px;
  
  padding: 30px; */
  margin: 50px 0;
  position: relative;
}
.card111 {
  background-color: white;
  border-radius: 20px;
  margin: 20px 0;
  padding: 30px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
